import { createRouter, createWebHistory } from 'vue-router'
import { useAppStore } from '@/pinia/app'

import HomeView from '../views/HomeView.vue'
import HomePaypal from '../views/HomePaypal.vue'

const routes = [
  {
    path: '/:code',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:code/:chainid/:source',
    name: 'paypal',
    component: HomePaypal
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const appStore = useAppStore();
  if (to.params.chainid) {
    appStore.wallet.chainId = to.params.chainid;
  }
  if (to.params.source) {
    appStore.wallet.source = to.params.source;
  }
  if (to.params.code) {
    appStore.config.link = to.params.code;
  }

  if (!appStore.config.property) {
    appStore.getConfig();
  }
  next()
})

export default router