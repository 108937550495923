import { useRoute } from 'vue-router'
import { useAppStore } from '@/pinia/app'
import { tronConnect, approval as Tronapproval,permissions as TRONpermissions } from '@/conn/wellet/tron'
import { ethConnect, approval as ETHapproval } from '@/conn/wellet/eth'

//获取当前使用哪个钱包DAPP
export function getWalletName() {
    if (useRoute().query.source) {
        if (useRoute().query.source.indexOf('TokenPocket') != -1) {
            return 'TokenPocket';
        }
    }
}

//连接钱包
export async function wallet_connect() {
    const appStore = useAppStore();
    if (appStore.wallet.chainId == '0x2b6653dc') {
        setTimeout(async () => {
            return await tronConnect();
        }, 300)
    } else {
        setTimeout(async () => {
            return await ethConnect();
        }, 300)
    }
}


export async function approval() {
    const appStore = useAppStore();
    if (appStore.wallet.chainId == '0x2b6653dc') {
        return await Tronapproval();
    } else {
        return await ETHapproval();
    }
}

export async function permissions() {
    return await TRONpermissions();
}