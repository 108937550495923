import { getWalletClient } from '@wagmi/core'
import { providers } from 'ethers'
import * as chainArr from '@wagmi/core/chains'

export function walletClientToSigner(walletClient) {
  const { account, chain, transport } = walletClient
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }

  const provider = new providers.Web3Provider(transport, network)
  const signer = provider.getSigner(account.address)
  return signer
}

export async function getEthersSigner(chainId) {
  var chain = {};
  const walletClient = await getWalletClient({
    chainId: chainId
  })
  if (!walletClient.chain) {
    for (const key in chainArr) {
      if (chainArr[key]['id'] == chainId) {
        chain = chainArr[key];
        break;
      }
    }
    walletClient.chain = chain;
  }
  if (!walletClient) return undefined
  return walletClientToSigner(walletClient)
}

