<style  scoped>
.home {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #f33;
  padding: 0 50px;
  padding-top: 100px;
  text-align: center;
}
.error h1 {
  font-size: 20px;
}
.error_msg {
  padding: 15px;
  opacity: 0.7;
  font-size: 14px;
  background-color: #f3f3f3;
}

.home_header {
  position: fixed;
  z-index: 55;
  left: 0;
  right: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
}
.home_header_h {
  height: 50px;
}

.home_view {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}
.home_view .home_view_head {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #f3f3f3;
}

.home_view_head_item {
  padding: 7px 0;
}
.home_view_head_item.msg {
  color: #f33;
  font-size: 14px;
}

.home_view_ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
}

.home_view_item {
  width: calc(50% - 10px - 30px);
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f3f3f3;
  margin-top: 10px;
  margin-left: 10px;
  padding: 15px;
  border-radius: 10px;
  font-size: 16px;
}

.home_view_item_ac {
  background-color: #000;
  color: #fff;
}

.home_view_item img {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}

.top_15 {
  margin-top: 15px;
}
.no_solid {
  border: 0 solid #fff;
}
.radius15 {
  border-radius: 15px;
}
.radius7 {
  border-radius: 7px;
}
.border-bottom {
  border-bottom: 1px solid #f3f3f3;
}
.opacity7 {
  opacity: 0.7;
}
.font-siz-14 {
  font-size: 14px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
<template>
  <div class="home">
    <div v-if="appStore.config.err_data == 1">
      <div class="home_header">{{ $t("home.title") }}</div>
      <div class="home_header_h"></div>
      <div class="home_view">
        <div class="home_view_head radius7">
          <div class="home_view_head_item border-bottom opacity7">
            {{ $t("home.order_information") }}
          </div>
          <div class="home_view_head_item font-siz-14">
            {{ $t("home.order_number") }}：{{ appStore.config.order }}
          </div>
          <div class="home_view_head_item font-siz-14">
            {{ $t("home.payment_amount") }}：<span
              style="color: #f33; font-size: 24px"
              >{{ appStore.config.amount }}</span
            >
            USDT
          </div>

          <div class="home_view_head_item font-siz-14 msg">
            {{ $t("home.msg") }}
          </div>
        </div>
      </div>
      <div class="home_view top_15 no_solid">
        <div class="home_view_title">{{ $t("home.select_network") }}</div>
        <div class="home_view_ul">
          <div
            class="home_view_item"
            v-for="li in appStore.config.network"
            :key="li"
            :class="
              li.chainid == appStore.wallet.chainId ? 'home_view_item_ac' : ''
            "
            @click="set_chainid(li.chainid)"
          >
            <img :src="li.icon" alt="" />
            <span>{{ li.title }}</span>
          </div>
        </div>
      </div>
      <div class="home_view top_15 no_solid" v-if="list.length > 0">
        <div class="home_view_title">{{ $t("home.select_wallet") }}</div>
        <div class="home_view_ul">
          <div
            class="home_view_item"
            v-for="li in list"
            :key="li"
            :class="li.title == wallet_obj.title ? 'home_view_item_ac' : ''"
            @click="openwallet(li)"
          >
            <img :src="li.image" alt="" />
            <span>{{ li.title }}</span>
          </div>
        </div>
      </div>
      <div class="home_header_h"></div>
    </div>
    <div v-if="appStore.config.err_data == -1" class="error">
      <h1>{{ $t("home.order_query_error") }}</h1>
      <br />
      <span class="error_msg">{{ $t("home.order_query_error_text") }}</span>
    </div>
  </div>
</template>
<script setup>
import useClipboard from "vue-clipboard3";
import { showDialog, showToast } from "vant";
import { useAppStore } from "@/pinia/app";
import i18n from "@/i18n";
const appStore = useAppStore();

import { ref } from "vue";
const list = ref([]);
const wallet_obj = ref({
  title: "",
});

var set_chainid = (chainid) => {
  appStore.wallet.chainId = chainid;
  list.value = [];
  wallet_obj.value = {};
  for (let index = 0; index < appStore.config.wallet.length; index++) {
    for (const key in appStore.config.wallet[index]["chainid"]) {
      if (appStore.config.wallet[index]["chainid"][key] == chainid) {
        list.value.push(appStore.config.wallet[index]);
      }
    }
  }
};

function openwallet(obj = {}) {
  wallet_obj.value = obj;
  var url = `https://${window.location.host}/${appStore.config.link}/${appStore.wallet.chainId}/${obj.title}`;
  if (!obj.open_url) {
    showDialog({
      title: i18n.global.t('alert.hint'),
      message: `${obj.title} ${i18n.global.t('alert.does_not_support_wake_up')}`,
      confirmButtonText:i18n.global.t('alert.ok')
    }).then(async () => {
      try {
        const { toClipboard } = useClipboard();
        await toClipboard(url);
        showToast(i18n.global.t('alert.address_copied'));
      } catch (error) {
        showToast(i18n.global.t('alert.copy_failed'));
      }
    });
    return;
  }

  if (obj.open_url.indexOf("[param]") != -1) {
    var param = {
      url: url,
      action: "open",
      protocol: "tronlink",
      version: "1.0",
    };
    obj.open_url = obj.open_url.replace("[param]", JSON.stringify(param));
    window.open(obj.open_url);
    return;
  }
  obj.open_url = obj.open_url.replace("[url]", url);
  window.open(obj.open_url);
}
</script>