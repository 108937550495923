import axios from 'axios'

const instance = axios.create({
    baseURL: 'https://api.duht.org/paypal',
    // baseURL: 'http://dapp.com/api/paypal',
    timeout: 15000
});

instance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    return Promise.reject(error);
});

export default instance;