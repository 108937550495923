<style scoped>
  body,html,#app{
    list-style: none;
    width: 100%;
    height: 100%;
    background-color: transparent;

    padding: 0;
    margin: 0;
  }
</style>
<template>
  <router-view/>
</template>