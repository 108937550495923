export default {
    title: "Payment page",
    home: {
        title: "Please select your payment channel",
        order_information: "order information",
        order_number: "order number",
        payment_amount: "Payment amount",
        msg: "Due to third-party recharge risk control restrictions, currently only USDT can be recharged. Please transfer and recharge to the corresponding wallet, and the recharge will be automatically credited to your account if the recharge is successful.",
        select_network: "Please select a network",
        select_wallet: "Please select wallet",
        order_query_error: "Order query error",
        order_query_error_text:"No valid information has been found for the current order number, please contact customer service to obtain the correct order number!",
    },
    paypage: {
        accounts_receivable:"Accounts receivable",
        officially_certified:"Officially certified",
        amount:"Amount",
        maximum:"max",
        assets:"assets",
        transaction_fee:"transaction fee",
        slow:"slow",
        medium:"medium",
        extreme_speed:"quick",
        next_step:"Next step",
        no_max:"Fixed amount, not clickable"
    },
    alert:{
        hint:"hint",
        does_not_support_wake_up:"The wallet does not support direct wake-up, please click OK to copy the address and open it in the wallet browser!",
        copy_failed:"Copy failed",
        address_copied:"The address has been copied. Please open the wallet APP and paste the address to access.",
        ok:"OK",

        unlock:"Please unlock your wallet first",
        connection_refused:"You have refused to connect to the wallet",
        failed_wallet:"Failed to connect to wallet",
        payment_failed:"payment successful"
    }
}