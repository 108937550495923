export default {
    title: "支付页面",
    home: {
        title: "请选择您的支付渠道",
        order_query_error: "订单查询出错",
        order_query_error_text:"当前订单号未查询出有效的信息，请联系客服获取正确的订单号！",
        order_information: "订单信息",
        order_number: "订单号",
        payment_amount: "付款金额",
        msg: "由于第三方充值风控限制，目前只能USDT充值，请针对相应钱包进行转账充值,充值成功自动到账。",
        select_network: "请选择网络",
        select_wallet: "请选择钱包"
    },
    paypage: {
        accounts_receivable:"收款账户",
        officially_certified:"官方已认证",
        amount:"数额",
        maximum:"最大",
        assets:"资产",
        transaction_fee:"交易费",
        slow:"缓慢",
        medium:"中等",
        extreme_speed:"极速",
        next_step:"下一步",
        no_max:"固定金额，不可点击"
    },
    alert:{
        hint:"小提示",
        does_not_support_wake_up:"钱包不支持直接唤醒，请点击确定复制地址在钱包浏览器中打开！",
        copy_failed:"复制失败",
        address_copied:"地址已复制，请打开钱包APP粘贴地址访问",
        ok:"确定",

        unlock:"请先解锁钱包",
        connection_refused:"您拒绝了连接钱包",
        failed_wallet:"连接钱包失败",
        payment_failed:"支付成功"
    }
}