import { createI18n } from 'vue-i18n'

import zhLang from './lang/zh'
import enLang from './lang/en'

const messages = {
    en: enLang,
    zh: zhLang
}

const i18n = createI18n({
    locale: 'zh',
    messages
})

export default i18n;